<template>
    <NavigationDropdown
      isMonochromatic
      :items-data="[
        {
          key: 'color-1',
          route: {
            name: 'essaiChildGeneralInformation',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Infos générales',
        },
        {
          key: 'color-1',
          route: {
            name: 'essaiChildParcelle',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Fiche parcellaire',
        },
        {
          key: 'color-1',
          route: {
            contains: 'ExperimentalTask',
            name: 'essaiChildCalendar',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Calendrier',
        },
        {
          key: 'color-1',
          route: {
            name: 'essaiChildTestPlan',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Plan d’essai',
        },
        {
          key: 'color-1',
          route: {
            name: 'essaiChildFicheBilan',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Bilan',
        },
        {
          key: 'color-1',
          route: {
            name: 'essaiChildFiles',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Fichiers',
        },
        {
          key: 'color-1',
          route: {
            name: 'essaiChildFixedReports',
            params: {
              id: $route.params.id,
            },
          },
          label: 'Rapports statistiques',
        },
      ]"
    >
    </NavigationDropdown>
  </template>

<script>
import NavigationDropdown from '@/components/layout/NavigationDropdown.vue'

export default {
  name: 'NavigationDropdownEssaiChild',

  components: {
    NavigationDropdown,
  },

  props: {
    protocol: {
      type: Object,
    },
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

  <style lang="scss" scoped>
  </style>
